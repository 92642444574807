<template>
  <!-- 发运信息详情 -->
  <div class="app-container">
    <el-row class="grid-content bg-purple-dark">
      <div>上架单基本信息</div>
    </el-row>
    <el-form label-width="102px">
      <div class="el-lt" style="width:100%;">
        <el-row :span="24">
          <el-col :span="8">
            <el-form-item label="上架单号：">{{ DetailsDatas.shelfId }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="入库单号：">{{ DetailsDatas.trayId }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="关联单号：">{{ DetailsDatas.orderId }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="上架时间：">{{ DetailsDatas.createTime }}</el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>

    <el-row class="grid-content bg-purple-dark">
      <div>上架明细</div>
    </el-row>
    <el-table ref="multipleTable" :summary-method="getTotals" show-summary class="mb-3" :data="ShippingInfoable" :header-cell-style="{background:'#fafafa'}" max-height="500px">
      <el-table-column
        type="index"
        :label="$t('page.No')"
        align="center"
      />
      <el-table-column
        prop="platformCode"
        label="平台"
        align="center"
      />
      <el-table-column
        prop="siteCode"
        label="站点"
        align="center"
      />
      <el-table-column
        prop="trayCode"
        label="托盘"
        align="center"
      />
      <el-table-column
        prop="sku"
        label="SKU"
        align="center"
      />
      <el-table-column
        prop="style"
        label="Style"
        align="center"
      />

      <el-table-column
        prop="color"
        label="Color"
        align="center"
      />
      <el-table-column
        prop="size"
        label="Size"
        align="center"
      />
      <el-table-column
        prop="actualPairs"
        label="上架数量"
        align="center"
      />
    </el-table>
    <el-row class="grid-content bg-blue" type="flex" justify="center">
      <el-button @click="cancle()">{{ $t('title.back') }}</el-button>
    </el-row>
  </div>

</template>

<script>
import { findShelfDetail } from '@/api/stockin'
import { Mixin } from '@/mixin/mixin.js'
export default {
  mixins: [Mixin],
  data() {
    return {
      dataObj: {},
      DetailsDatas: {},
      ShippingInfoable: [],
      tatolPairs: 0
    }
  },

  mounted() {
    const { createTime, shelfId, trayId, orderId } = this.$route.query
    this.DetailsDatas = { createTime, shelfId, trayId, orderId }
    this._findShelfDetail()
  },
  methods: {
    cancle() {
      this.$router.go(-1)
    },
    // 获取详情数据
    async _findShelfDetail() {
      const { shelfId, tableSuffix } = this.$route.query
      const data = { shelfId, tableSuffix }
      const { datas } = await findShelfDetail(data)
      this.ShippingInfoable = datas.voList
      this.tatolPairs = datas.tatolPairs
      // if (this.DetailsDatas.transportStatus) {
    },
    // 出库单总价显示
    getTotals(param) {
      const { columns } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = this.$t('page.total')
          return
        }
        if (column.property === 'actualPairs') { // 收货箱数
          sums[index] = this.tatolPairs
        } else {
          sums[index] = ''
        }
      })
      return sums
    }

  }
}
</script>
<style lang="scss" scope>
.float-right {
  display: flex;
  justify-content: flex-end;
}
</style>
